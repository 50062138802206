<template>
  <div id="knowledge-base-article" class="tw-overflow-hidden tw-h-full">
    <b-row class="tw-overflow-hidden tw-h-full">
      <b-col class="tw-h-full" md="9">
        <div class="tw-border tw-bg-white tw-p-6 tw-rounded-2xl tw-overflow-hidden tw-h-full">
          <TaskTipTapEditor
            v-model="article.description"
            class="tw-overflow-y-auto board-scroll tw-h-full tw-pt-[1px]"
          />
        </div>
      </b-col>

      <b-col class="position-fixed position-right-0" md="3">
        <div class="tw-border tw-bg-white tw-p-6 tw-rounded-2xl">
          <b-form-group :label="$t('Title')">
            <b-form-input v-model="article.title" :placeholder="$t('Write the title of the article here...')" block />
          </b-form-group>
          <!--          <b-form-group :label="$t('Category')">-->
          <!--            <b-form-select v-model="article.category" :placeholder="$t('Enter category')" />-->
          <!--          </b-form-group>-->
          <b-form-group :label="$t('Group')">
            <v-select v-model="article.group" :options="groups" :placeholder="$t('Select group')" label="title" />
          </b-form-group>

          <button
            class="tw-w-full tw-bg-slate-100 tw-text-slate-600 tw-px-3 tw-py-2 tw-rounded-lg hover:tw-bg-slate-500 hover:tw-text-slate-100 tw-border tw-border-slate-100 hover:tw-border-slate-200 tw-transition"
            @click="updateArticle"
          >
            {{ $t('Update article') }}
          </button>

          <a
            :href="$router.resolve({name: 'knowledge-base-publication', params: {slug: article.id}}).href"
            class="tw-mt-2 tw-block tw-text-center tw-w-full tw-text-red-700 tw-px-3 tw-py-2 tw-rounded-lg hover:tw-bg-red-50 tw-border tw-border-white tw-transition"
            >{{ $t('Cancel') }}</a
          >
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {BCol, BFormGroup, BFormInput, BRow} from 'bootstrap-vue';
import WikiService from '@/services/wiki.service';
import vSelect from 'vue-select';
import TaskTipTapEditor from '@/components/Task/TaskTipTapEditor.vue';

export default {
  components: {
    TaskTipTapEditor,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    vSelect,
  },
  data() {
    return {
      groups: [],
      article: {
        title: '',
        description: '',
        group: null,
      },
      value: null,
      editor: null,
    };
  },
  mounted() {
    this.getArticle();
    WikiService.getGroups().then(r => (this.groups = r.data));
  },
  methods: {
    getArticle() {
      WikiService.getArticle(this.$route.params.slug).then(r => {
        this.article = r.data;
        // this.getGroup();
      });
    },
    createArticle() {
      WikiService.createArticle({
        ...this.article,
        group: this.article.group.id,
      }).catch(error => {
        console.log('Saving failed: ', error);
      });
    },
    updateArticle() {
      WikiService.updateArticle(this.article.id, {
        ...this.article,
        group: this.article.group.id,
      }).then(() => {
        this.$router.push({
          name: 'knowledge-base-publication',
          params: {slug: this.article.id},
        });
      });
    },
  },
};
</script>

<style lang="scss"></style>
